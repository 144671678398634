var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    class: _vm.device + "-product",
    attrs: {
      id: "product_content"
    }
  }, [_c("div", {
    staticClass: "bannerTop"
  }, [_c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc" && _vm.language == 1,
      expression: "device=='pc' && language==1"
    }],
    staticClass: "banner-pc",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: require("../assets/product/product_banner_20230727.jpg")
    }
  }), _c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc" && _vm.language == 2,
      expression: "device=='pc' && language==2"
    }],
    staticClass: "banner-pc",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: require("../assets/product/product_banner_en_20230727.jpg")
    }
  }), _c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "pc" && _vm.language == 3,
      expression: "device=='pc' && language==3"
    }],
    staticClass: "banner-pc",
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: require("../assets/product/product_banner_tr_20230727.jpg")
    }
  }), _c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "mobile" && _vm.language == 1,
      expression: "device=='mobile' && language==1"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: require("../assets/product/product_banner_mobile_20230727.jpg")
    }
  }), _c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "mobile" && _vm.language == 2,
      expression: "device=='mobile' && language==2"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: require("../assets/product/product_banner_mobile_en_20230727.jpg")
    }
  }), _c("img", {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.device == "mobile" && _vm.language == 3,
      expression: "device=='mobile' && language==3"
    }],
    staticStyle: {
      width: "100%"
    },
    attrs: {
      src: require("../assets/product/product_banner_mobile_tr_20230727.jpg")
    }
  })]), _c("div", {
    staticClass: "content product-concept"
  }, [_c("div", {
    staticClass: "content-header"
  }, [_c("div", {
    staticClass: "concept-content",
    attrs: {
      id: "product_concept"
    }
  }, [_c("div", {
    staticClass: "left animate__animated animate__fadeInLeft animate__slow display-none"
  }, _vm._l(_vm.aLinks, function (item, index) {
    return _c("a", {
      key: index,
      staticClass: "navItem",
      class: {
        current: index == _vm.currentIndex
      },
      on: {
        click: function ($event) {
          return _vm.scrollIntoView(item.id, index);
        }
      }
    }, [_vm._m(0, true), _c("span", [_vm._v(_vm._s(item.name[parseInt(_vm.language) - 1]))])]);
  }), 0), _c("div", {
    staticClass: "right animate__animated animate__fadeInUp animate__slow display-none"
  }, [_c("p", [_vm._v(_vm._s(_vm.mapText.concept_text_01[parseInt(_vm.language - 1)]))]), _c("p", [_vm._v(_vm._s(_vm.mapText.concept_text_02[parseInt(_vm.language - 1)]))])])])])]), _c("div", {
    ref: "hot_product",
    staticClass: "content hot-product"
  }, [_c("div", {
    staticClass: "content-header"
  }, [_c("div", {
    ref: "hot_product_title",
    staticClass: "title animate__animated animate__slow display-none"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.hot_product[parseInt(_vm.language) - 1]))])]), _c("div", {
    staticClass: "divide-line"
  }), _c("div", {
    ref: "hot_product_subTitle",
    staticClass: "sub-title animate__animated animate__slow display-none"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.buy_product[parseInt(_vm.language) - 1]))])])]), _c("div", {
    staticClass: "product-intro"
  }, [_c("div", {
    staticClass: "product-item product-cwq",
    attrs: {
      id: "product_cwq"
    }
  }, [_c("div", {
    ref: "hot_product_left_01",
    staticClass: "product-img animate__animated animate__slow display-none"
  }, [_c("img", {
    attrs: {
      src: require("../assets/product/changweiqing_20230728.png"),
      alt: ""
    }
  })]), _c("div", {
    ref: "hot_product_right_01",
    staticClass: "product-text animate__animated animate__slow display-none"
  }, [_vm.device == "mobile" ? _c("div", {
    staticClass: "mobile-title"
  }, [_c("div", {
    staticStyle: {
      "padding-top": "15px"
    }
  }, [_c("div", {
    staticClass: "intro-bigger"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.product_cwq_intro_0_1[parseInt(_vm.language) - 1]))])]), _c("div", {
    staticClass: "intro-line"
  })]), _vm._m(1)]) : _vm._e(), _vm.device == "pc" ? _c("div", {
    staticClass: "intro-bigger"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.product_cwq_intro_0_1[parseInt(_vm.language) - 1]))])]) : _vm._e(), _vm.device == "pc" ? _c("div", {
    staticClass: "intro-line"
  }) : _vm._e(), _c("div", [_c("span", [_vm._v(_vm._s(_vm.mapText.product_cwq_intro_2[parseInt(_vm.language) - 1]))])]), _c("div", [_c("span", [_vm._v(_vm._s(_vm.mapText.product_cwq_intro_4[parseInt(_vm.language) - 1]))])]), _c("div", {
    staticClass: "buy"
  }, [_c("div", {
    staticClass: "button",
    on: {
      click: function ($event) {
        return _vm.toBuy(_vm.cwq_buyLink);
      }
    }
  }, [_vm._v(_vm._s(_vm.mapText.product_buy_button[parseInt(_vm.language) - 1]))]), _vm.device == "pc" ? _c("div", {
    staticClass: "img-01"
  }, [_c("img", {
    attrs: {
      src: require("../assets/product/product_01.png"),
      alt: ""
    }
  })]) : _vm._e()])])]), _c("div", {
    staticClass: "product-item product-pp",
    attrs: {
      id: "product_pp"
    }
  }, [_vm.device == "mobile" ? _c("div", {
    ref: "hot_product_right_02",
    staticClass: "product-img animate__animated animate__slow display-none"
  }, [_c("img", {
    attrs: {
      src: require("../assets/product/pupuguan_20230728.png"),
      alt: ""
    }
  })]) : _vm._e(), _c("div", {
    ref: "hot_product_left_02",
    staticClass: "product-text animate__animated animate__slow display-none"
  }, [_vm.device == "mobile" ? _c("div", {
    staticClass: "mobile-title"
  }, [_c("div", {
    staticStyle: {
      "padding-top": "15px"
    }
  }, [_c("div", {
    staticClass: "intro-bigger"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.product_pp_intro_0_1[parseInt(_vm.language) - 1]))])]), _c("div", {
    staticClass: "intro-line"
  })]), _vm._m(2)]) : _vm._e(), _vm.device == "pc" ? _c("div", {
    staticClass: "intro-bigger"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.product_pp_intro_0_1[parseInt(_vm.language) - 1]))])]) : _vm._e(), _vm.device == "pc" ? _c("div", {
    staticClass: "intro-line"
  }) : _vm._e(), _c("div", [_c("span", [_vm._v(_vm._s(_vm.mapText.product_pp_intro_1[parseInt(_vm.language) - 1]))])]), _c("div", [_c("span", [_vm._v(_vm._s(_vm.mapText.product_pp_intro_2[parseInt(_vm.language) - 1]))])]), _c("div", [_c("span", [_vm._v(_vm._s(_vm.mapText.product_pp_intro_3[parseInt(_vm.language) - 1]))])]), _c("div", {
    staticClass: "buy"
  }, [_c("div", {
    staticClass: "button",
    on: {
      click: function ($event) {
        return _vm.toBuy(_vm.pp_buyLink);
      }
    }
  }, [_vm._v(_vm._s(_vm.mapText.product_buy_button[parseInt(_vm.language) - 1]))]), _vm.device == "pc" ? _c("div", [_c("img", {
    attrs: {
      src: require("../assets/product/product_02.png"),
      alt: ""
    }
  })]) : _vm._e()])]), _vm.device == "pc" ? _c("div", {
    ref: "hot_product_right_02",
    staticClass: "product-img animate__animated animate__slow display-none"
  }, [_c("img", {
    attrs: {
      src: require("../assets/product/pupuguan_20230728.png"),
      alt: ""
    }
  })]) : _vm._e()]), _c("div", {
    staticClass: "product-item product-uu",
    attrs: {
      id: "product_uu"
    }
  }, [_c("div", {
    ref: "hot_product_left_03",
    staticClass: "product-img product-text animate__animated animate__slow display-none"
  }, [_c("img", {
    attrs: {
      src: require("../assets/product/youyouguan_20230728.png"),
      alt: ""
    }
  })]), _c("div", {
    ref: "hot_product_right_03",
    staticClass: "product-text product-text animate__animated animate__slow display-none"
  }, [_vm.device == "mobile" ? _c("div", {
    staticClass: "mobile-title"
  }, [_c("div", {
    staticStyle: {
      "padding-top": "15px"
    }
  }, [_c("div", {
    staticClass: "intro-bigger"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.product_uu_intro_0_1[parseInt(_vm.language) - 1]))])]), _c("div", {
    staticClass: "intro-line"
  })]), _vm._m(3)]) : _vm._e(), _vm.device == "pc" ? _c("div", {
    staticClass: "intro-bigger"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.product_uu_intro_0_1[parseInt(_vm.language) - 1]))])]) : _vm._e(), _vm.device == "pc" ? _c("div", {
    staticClass: "intro-line"
  }) : _vm._e(), _c("div", [_c("span", [_vm._v(_vm._s(_vm.mapText.product_uu_intro_2[parseInt(_vm.language) - 1]))])]), _c("div", [_c("span", [_vm._v(_vm._s(_vm.mapText.product_uu_intro_3[parseInt(_vm.language) - 1]))])]), _c("div", [_c("span", [_vm._v(_vm._s(_vm.mapText.product_uu_intro_4[parseInt(_vm.language) - 1]))])]), _c("div", [_c("span", [_vm._v(_vm._s(_vm.mapText.product_uu_intro_5[parseInt(_vm.language) - 1]))])]), _c("div", {
    staticClass: "buy"
  }, [_c("div", {
    staticClass: "button",
    on: {
      click: function ($event) {
        return _vm.toBuy(_vm.uu_buyLink);
      }
    }
  }, [_vm._v(_vm._s(_vm.mapText.product_buy_button[parseInt(_vm.language) - 1]))]), _vm.device == "pc" ? _c("div", [_c("img", {
    attrs: {
      src: require("../assets/product/product_03.png"),
      alt: ""
    }
  })]) : _vm._e()])])])])]), _c("div", {
    ref: "product_layout",
    staticClass: "content product-layout",
    attrs: {
      id: "product_layout"
    }
  }, [_c("div", {
    staticClass: "content-header"
  }, [_c("div", {
    ref: "product_layout_title",
    staticClass: "title animate__animated animate__slow display-none"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.product_layout[parseInt(_vm.language) - 1]))])]), _c("div", {
    staticClass: "divide-line"
  }), _c("div", {
    ref: "product_layout_subTitle",
    staticClass: "sub-title animate__animated animate__slow display-none"
  }, [_c("span", [_vm._v(_vm._s(_vm.mapText.product_layout_slogan[parseInt(_vm.language) - 1]))])])]), _c("div", {
    ref: "product_layout_tags",
    staticClass: "layout-tags animate__animated animate__slow display-none"
  }, _vm._l(_vm.productLayouts, function (item, index) {
    return _c("div", {
      key: index,
      staticClass: "layout-tag",
      class: {
        active: index == _vm.activeIndex,
        "hvr-grow-shadow": _vm.device == "pc"
      },
      on: {
        click: function ($event) {
          _vm.activeIndex = index;
        }
      }
    }, [_c("span", [_vm._v(_vm._s(item.name[parseInt(_vm.language) - 1]))])]);
  }), 0), _vm.device == "pc" ? _c("div", {
    ref: "product_layout_info",
    staticClass: "layout-product-info animate__animated animate__slow display-none"
  }, _vm._l(_vm.productLayouts[_vm.activeIndex].productList, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "layout-product-img",
      class: "img" + index
    }, [_c("img", {
      attrs: {
        src: item.img,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "layout-product-content"
    }, [_c("div", [_c("span", [_vm._v(_vm._s(item.introduce_01[parseInt(_vm.language) - 1]))])]), _c("div", [_c("span", [_vm._v(_vm._s(item.introduce_02[parseInt(_vm.language) - 1]))])])]), _c("div", {
      staticClass: "button",
      on: {
        click: function ($event) {
          return _vm.changeRoute(item.router);
        }
      }
    }, [_vm._v(_vm._s(_vm.mapText.product_layout_detail[parseInt(_vm.language) - 1]))])]);
  }), 0) : _vm._e(), _vm.device == "mobile" ? _c("div", {
    ref: "product_layout_info",
    staticClass: "layout-product-info animate__animated animate__slow display-none"
  }, [_vm._m(4), _c("div", {
    ref: "item_block",
    staticClass: "product-item-block"
  }, _vm._l(_vm.productLayouts[_vm.activeIndex].productList, function (item, index) {
    return _c("div", {
      key: index
    }, [_c("div", {
      staticClass: "layout-product-img",
      class: "img" + index
    }, [_c("img", {
      attrs: {
        src: item.img,
        alt: ""
      }
    })]), _c("div", {
      staticClass: "layout-product-content"
    }, [_c("div", [_c("span", [_vm._v(_vm._s(item.introduce_01[parseInt(_vm.language) - 1]))])]), _c("div", [_c("span", [_vm._v(_vm._s(item.introduce_02[parseInt(_vm.language) - 1]))])])]), _c("div", {
      staticClass: "button",
      on: {
        click: function ($event) {
          return _vm.changeRoute(item.router);
        }
      }
    }, [_vm._v(_vm._s(_vm.mapText.product_layout_detail[parseInt(_vm.language) - 1]))])]);
  }), 0), _c("div", {
    staticClass: "arrow left-arrow",
    on: {
      click: function ($event) {
        $event.stopPropagation();
        return _vm.swipRight($event);
      }
    }
  }, [_c("i", {
    staticClass: "el-icon-arrow-right"
  })])]) : _vm._e()])]);
};
var staticRenderFns = [function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "circle"
  }, [_c("div", {
    staticClass: "inner"
  }), _c("div", {
    staticClass: "outer"
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("img", {
    attrs: {
      src: require("../assets/product/product_01.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("img", {
    attrs: {
      src: require("../assets/product/product_02.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", [_c("img", {
    attrs: {
      src: require("../assets/product/product_03.png"),
      alt: ""
    }
  })]);
}, function () {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "arrow left-arrow"
  }, [_c("i", {
    staticClass: "el-icon-arrow-left"
  })]);
}];
render._withStripped = true;
export { render, staticRenderFns };