import "core-js/modules/es.array.push.js";
import { mapState } from 'vuex';
const mapText = {
  product_concept: ['产品理念', 'Product Concept', '產品理念'],
  product_cwq: ['常卫清®', 'ColoClear®', '常衛清®'],
  product_pp: ['噗噗管®', 'Pupu Tube®', '噗噗管®'],
  product_uu: ['幽幽管®', 'UU Tube®', '幽幽管®'],
  product_layout: ['产品布局', 'Product Layout', '產品佈局'],
  product_layout_slogan: ['管线丰富 | 居家操作', 'Rich Pipelines | Home Operation', '管線豐富 | 居家操作'],
  hot_product: ['爆款推荐', 'Top-selling Item', '爆款推薦'],
  buy_product: ['购买畅销单品', 'Shop Best Sellers', '購買暢銷單品'],
  product_cwq_intro_0: ['居家 无创 精准', 'Home Non-invasive Precision', '居家 無創 精准'],
  // product_cwq_intro_0_1: ['常卫清®-肠癌早筛产品', 'ColoClear®-Early screening products for colon cancer', '常衛清®-腸癌早篩產品'],
  product_cwq_intro_0_1: ['常卫清®-肠癌早筛产品', 'ColoClear®', '常衛清®-腸癌早篩產品'],
  product_cwq_intro_1: ['中国首个获批', "China's first approval", '中國首個獲批'],
  // product_cwq_intro_2: ['中国首个癌症早筛前瞻性、大规模、多中心注册临床试验验证通过', "China's first cancer early screening prospective, large-scale, multi center registered clinical trial passed validation", '中國首個癌症早篩前瞻性、大規模、多中心注册臨床試驗驗證通過'],
  product_cwq_intro_2: ['中国首个癌症早筛前瞻性、大规模、多中心注册临床试验验证通过', "The Only Clinically Proven & NMPA Approved CRC Screening Test in China", '中國首個癌症早篩前瞻性、大規模、多中心注册臨床試驗驗證通過'],
  product_cwq_intro_3: ['肠癌灵敏度95.5%,进展期腺瘤灵敏度63.5%,肠癌阴性预测值99.6%', 'The sensitivity of colon cancer was 95.5%, the sensitivity of advanced adenoma was 63.5%, and the negative predictive value of colon cancer was 99.6%', '腸癌靈敏度95.5%，進展期腺瘤靈敏度63.5%，腸癌陰性預測值99.6%'],
  // product_cwq_intro_4: ['适用于40-74岁的肠癌高风险人群筛查', 'It is applicable to screening of high-risk population of colon cancer aged 40-74', '適用於40-74歲的腸癌高風險人群篩查'],
  product_cwq_intro_4: ['适用于40-74岁的肠癌高风险人群筛查', '', '適用於40-74歲的腸癌高風險人群篩查'],
  product_pp_intro_0: ['居家 快速 高效', 'Home Fast Efficient', '居家 快速 高效'],
  product_pp_intro_0_1: ['噗噗管®-便隐血自测产品', 'Pupu Tube®', '噗噗管®-便隱血自測產品'],
  // product_pp_intro_0_1: ['噗噗管®-便隐血自测产品', 'Pupu Tube®-Fecal occult blood self test product', '噗噗管®-便隱血自測產品'],
  product_pp_intro_1: ['浙江大学肿瘤研究所郑树教授团队历经40余年的基层筛查时间探索', "The first and Only NMPA-approved Self-tests with Utility in CRC ", '浙江大學腫瘤研究所鄭樹教授團隊歷經40餘年的基層篩查時間探索'],
  // product_pp_intro_1: ['浙江大学肿瘤研究所郑树教授团队历经40余年的基层筛查时间探索', "Professor Zheng Shu's team of Zhejiang University Cancer Research Institute has explored the time of grass-roots screening for more than 40 years", '浙江大學腫瘤研究所鄭樹教授團隊歷經40餘年的基層篩查時間探索'],
  product_pp_intro_2: ['联合诺辉健康共同研发', '', '聯合諾輝健康共同研發'],
  // product_pp_intro_2: ['联合诺辉健康共同研发', 'Joint research and development with Nuofei Health', '聯合諾輝健康共同研發'],
  product_pp_intro_3: ['5分钟即可居家完成取样,检测,结果判读全流程操作', '', '5分鐘即可居家完成取樣，檢測，結果判讀全流程操作'],
  // product_pp_intro_3: ['5分钟即可居家完成取样,检测,结果判读全流程操作', 'The whole process of sampling, testing and result interpretation can be completed in 5 minutes at home', '5分鐘即可居家完成取樣，檢測，結果判讀全流程操作'],
  product_uu_intro_0: ['居家 便捷 简单', 'Home Convenient Easy', '居家 便捷 簡單'],
  product_uu_intro_0_1: ['幽幽管®-幽门螺杆菌自测产品', 'UU Tube®', '幽幽管®-幽門螺桿菌自測產品'],
  // product_uu_intro_0_1: ['幽幽管®-幽门螺杆菌自测产品', 'UU Tube®-Helicobacter pylori self-test products', '幽幽管®-幽門螺桿菌自測產品'],
  product_uu_intro_1: ['中国首个获批', "China's first approvaln", '中國首個獲批'],
  product_uu_intro_2: ['首次实现取样和检测"验孕棒"式独家一体化设计', 'The first and only Helicobacter pylori self-test product approved by NMPA', '首次實現取樣和檢測“驗孕棒”式獨家一體化設計'],
  // product_uu_intro_2: ['首次实现取样和检测"验孕棒"式独家一体化设计', 'It is the first time to realize the exclusive integrated design of sampling and testing "pregnancy test stick"', '首次實現取樣和檢測“驗孕棒”式獨家一體化設計'],
  product_uu_intro_3: ['检测前无需服药,检测时无需空腹', '', '檢測前無需服藥，檢測時無需空腹'],
  // product_uu_intro_3: ['检测前无需服药,检测时无需空腹', 'No need to take medicine before testing, and no need to empty stomach during testing', '檢測前無需服藥，檢測時無需空腹'],
  product_uu_intro_4: ['样本采集无特殊要求', '', '樣本採集無特殊要求'],
  // product_uu_intro_4: ['样本采集无特殊要求', 'No special requirements for sample collection', '樣本採集無特殊要求'],
  product_uu_intro_5: ['取样后静置10分钟,即可判读结果', ''],
  // product_uu_intro_5: ['取样后静置10分钟,即可判读结果', 'After sampling, let it stand for 10 minutes to interpret the results', 'After sampling, let it stand for 10 minutes to interpret the results'],
  product_buy_button: ['点击购买', 'Buy', '點擊購買'],
  product_layout_all_tag: ['全部', 'All', '全部'],
  product_layout_tag_01: ['幽门螺杆菌', 'Helicobacter Pylori', '幽門螺桿菌'],
  product_layout_tag_02: ['肠癌', 'Intestinal Cancer', '腸癌'],
  product_layout_tag_03: ['宫颈癌(在研)', 'Cervical Cancer (under research)', '宮頸癌（在研）'],
  product_layout_tag_04: ['肝癌(在研)', 'Liver Cancer (under research)', '肝癌（在研）'],
  product_layout_pp_introduce_01: ['中国首个获批', "China's first approval", '中國首個獲批'],
  product_layout_gzq_introduce_01: ['全球首个尿液样本', "The world's first urine sample", '全球首個尿液樣本'],
  product_layout_gzq_introduce_02: ['宫颈癌早筛服务(在研阶段)', 'Early screening service for cervical cancer (in research stage)', '宮頸癌早篩服務（在研階段）'],
  product_layout_ganzq_introduce_01: ['全球首个"全生命法则"', "The world's first 'law of life'", '全球首個“全生命法則”'],
  product_layout_ganzq_introduce_02: ['肝癌早筛服务(在研阶段)', 'Early screening service for liver cancer (in research stage)', '肝癌早篩服務（在研階段）'],
  product_layout_detail: ['产品详情', 'Detail', '產品詳情'],
  concept_text_01: ['以改变生命的轨迹为使命，始终坚持科学有依据、临床能验证、市场有前景、用户能接受的产品理念。', 'With the mission of changing the trajectory of life, we always adhere to the product concept of scientific basis, clinical verification, market prospect and user acceptance.', '以改變生命的軌跡為使命，始終堅持科學有依據、臨床能驗證、市場有前景、用戶能接受的產品理念。'],
  concept_text_02: ['通过自主研发的多组学创新技术，为用户提供适宜的解决方案和服务品质。', 'We will provide users with appropriate solutions and service quality through independently researched and developed multi group innovative technologies.', '通過自主研發的多組學創新技術，為用戶提供適宜的解決方案和服務品質。']
};
const aLinks = [{
  id: 'product_concept',
  name: mapText.product_concept
}, {
  id: 'product_cwq',
  name: mapText.product_cwq
}, {
  id: 'product_pp',
  name: mapText.product_pp
}, {
  id: 'product_uu',
  name: mapText.product_uu
}, {
  id: 'product_layout',
  name: mapText.product_layout
}];
const productList_01 = [{
  img: require('../assets/product/youyouguan_20230728.png'),
  introduce_01: mapText.product_cwq_intro_1,
  introduce_02: mapText.product_uu_intro_0_1,
  router: {
    path: './product_detail',
    query: {
      productName: '幽幽管®'
    }
  }
}];
const productList_02 = [{
  img: require('../assets/product/changweiqing_20230728.png'),
  introduce_01: mapText.product_cwq_intro_1,
  introduce_02: mapText.product_cwq_intro_0_1,
  router: {
    path: './product_detail',
    query: {
      productName: '常卫清®'
    }
  }
}, {
  img: require('../assets/product/pupuguan_20230728.png'),
  introduce_01: mapText.product_layout_pp_introduce_01,
  introduce_02: mapText.product_pp_intro_0_1,
  router: {
    path: './product_detail',
    query: {
      productName: '噗噗管®'
    }
  }
}];
const productList_03 = [{
  img: require('../assets/product/gongzhengqing_big.png'),
  introduce_01: mapText.product_layout_gzq_introduce_01,
  introduce_02: mapText.product_layout_gzq_introduce_02,
  router: {
    path: './product_detail',
    query: {
      productName: '宫证清™'
    }
  }
}];
const productList_04 = [{
  img: require('../assets/product/ganzhengqing_big.png'),
  introduce_01: mapText.product_layout_ganzq_introduce_01,
  introduce_02: mapText.product_layout_ganzq_introduce_02,
  router: {
    path: './product_detail',
    query: {
      productName: '苷证清™'
    }
  }
}];
const productLayouts = [{
  type: '00',
  name: mapText.product_layout_all_tag,
  productList: [...productList_01, ...productList_02, ...productList_03, ...productList_04]
}, {
  type: '01',
  name: mapText.product_layout_tag_01,
  productList: productList_01
}, {
  type: '02',
  name: mapText.product_layout_tag_02,
  productList: productList_02
}, {
  type: '03',
  name: mapText.product_layout_tag_03,
  productList: productList_03
}, {
  type: '04',
  name: mapText.product_layout_tag_04,
  productList: productList_04
}];
const cwq_buyLink = 'https://detail.tmall.com/item.htm?id=632009673812';
const uu_buyLink = 'https://detail.tmall.com/item.htm?id=666697281154';
const pp_buyLink = 'https://detail.tmall.com/item.htm?id=632518894008';
export default {
  data() {
    return {
      title: this.$api.api_title,
      img_list: {},
      currentIndex: 0,
      activeIndex: 0,
      aLinks,
      mapText,
      productLayouts,
      cwq_buyLink,
      uu_buyLink,
      pp_buyLink
    };
  },
  computed: {
    ...mapState({
      device: state => state.device,
      language: state => state.language
    })
  },
  created() {
    this.getBannerData();
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll, true);
  },
  destroyed() {
    window.removeEventListener('scroll', this.handleScroll, true);
  },
  methods: {
    getBannerData() {
      //顶部大图
      // TODO: 切换成查询产品聚合页banner接口
      this.$http.get(this.$api.cmsHome_bannerList, {
        params: {
          nhLanguage: this.$store.state.language
        }
      }).then(res => {
        if (res.code == 0 && res.data) {
          this.img_list = res.data[0];
        }
      });
    },
    scrollIntoView(id, index) {
      this.currentIndex = index;
      const el = document.getElementById(id);
      if (el) el.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center'
      });
    },
    toBuy(path) {
      window.open(path, '_blank');
    },
    changeRoute(router) {
      this.$router.push({
        path: router.path,
        query: router.query
      });
    },
    handleScroll() {
      // 爆款推荐
      const hot_product = this.$refs.hot_product.getBoundingClientRect().top;
      this.changeClass(hot_product, this.$refs.hot_product_title, 'animate__fadeInUp', 500);
      this.changeClass(hot_product, this.$refs.hot_product_subTitle, 'animate__fadeInUp', 500);
      this.changeClass(hot_product, this.$refs.hot_product_left_01, 'animate__fadeInLeft', 500);
      this.changeClass(hot_product, this.$refs.hot_product_right_01, 'animate__fadeInRight', 500);
      this.changeClass(hot_product, this.$refs.hot_product_left_02, 'animate__fadeInLeft', 100);
      this.changeClass(hot_product, this.$refs.hot_product_right_02, 'animate__fadeInRight', 100);
      this.changeClass(hot_product, this.$refs.hot_product_left_03, 'animate__fadeInLeft', -450);
      this.changeClass(hot_product, this.$refs.hot_product_right_03, 'animate__fadeInRight', -450);
      // 产品布局
      const product_layout = this.$refs.product_layout.getBoundingClientRect().top;
      this.changeClass(product_layout, this.$refs.product_layout_title, 'animate__fadeInUp', 500);
      this.changeClass(product_layout, this.$refs.product_layout_subTitle, 'animate__fadeInUp', 500);
      this.changeClass(product_layout, this.$refs.product_layout_tags, 'animate__fadeInUp', 350);
      this.changeClass(product_layout, this.$refs.product_layout_info, 'animate__fadeInUp', 350);
    },
    changeClass(distance, target, animate, top) {
      if (distance > top) return;
      target.classList.add(animate);
      target.classList.remove('display-none');
    },
    swipRight(e) {
      console.log(e);
      const center_block = this.$refs.item_block[0];
      console.log('🚀 ~ center_block', center_block, center_block.style.left);
      const newRight = parseInt(center_block.style.left) + 200;
      console.log('🚀 ~ newRight', newRight);
      center_block.style.left = newRight + 'px';
      // left_number = newLeft
    }
  }
};